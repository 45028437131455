.colorBlob > #color-blob {
  fill: url(#linearGradient-1);
}
.colorBlock {
  height: 5.6rem;
  width: 5.6rem;
  border-radius: 1.6rem;  
}

.colorBlobWrapper {
  z-index: 1;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  color: var(--accent-color);
}

.colorContent {
  /* margin-left: 8rem; */
  z-index: 2;  
  flex: 1;
  width: 0;
  padding: 0;
  margin: 0 0.4rem;
}

.auspiciousTitleWrapper {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
  align-items: center;
}

.text {
  color: white;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}