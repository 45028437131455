html {
  background-color: var(--app-bg-color);
}
body {  
  min-height: 100vh;
}

.title, .subtitle {
  font-family: var(--font);
  /* color: var(--accent-color) !important; */
}