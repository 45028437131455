.button {
  border: none;
  font-family: var(--font);
}

.small {
  border-radius: 0.5rem !important;
}

.primary,.primary:focus, .primary:active, .primary:hover {
  background-color: var(--primary-color);
  color: var(--primary-foreground-color);
}

.secondary,.secondary:focus, .secondary:active, .secondary:hover {
  background-color: var(--secondary-color);
  color: var(--secondary-foreground-color);
}

.accent,.accent:focus, .accent:active, .accent:hover {
  background-color: var(--accent-color);
  color: var(--accent-foreground-color);
}

.accent:disabled {
  background-color: var(--accent-color);
  color: var(--accent-foreground-color);
  opacity: 0.5;
}
.transparent {
  background-color: transparent;
}