.calendar {
  margin: 0 auto;
  max-width: 480px;  

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: calc(100vh - 62px);
  overflow-y: hidden;
}
.months {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-bottom: 1.85rem;
  background-color: var(--calendar-bg-color);
}
.month {
  flex: 1;
  min-width: 100%;
}
.calendarRow {
  flex-wrap: wrap;  
  margin: 0;  
  background-color: var(--calendar-bg-color);
}
.calendarRow:last-child {
  /* border-bottom: 1px solid var(--day-block-border-color); */
  padding-bottom: 0.4rem;
  margin-bottom: 0;
}
.dayBlock {
  flex: 0 0 14.2857142857%;
  /* border: 1px solid var(--day-block-border-color); */
  border-top: 0px;
  position: relative;
  padding: 0.4rem;  

  width: 100%;  
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.dayBlock+.dayBlock,.dayBlock:first-child {
  border-right: 0;
}

.dayBlockDisabled {
  color: lightgray;
}

.dayBlockContent {
  font-family: var(--font);
  text-align: center;
  height: 5vh;
  width: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.4rem;  
}

.dayBlockContentHeader {    
  color: var(--dayBlock-header-foreground-color);
  font-size: 1.1rem;
  font-weight: 900;
  text-transform: uppercase;  
}
.dayBlockSmallerContent {
  font-size: 0.8rem;
}

.dayBlockContentSelected {
  color: var(--day-block-today-color);
  border: 2px solid var(--day-block-today-color);  
}

.dayBlockContentToday {
  color: var(--day-block-today-foreground-color);
  background-color: var(--day-block-today-color);
  border-radius: 0.4rem;
  box-shadow: var(--day-block-today-shadow);
  font-weight: bold;
}

.dayBlockContentRoyal {
  font-weight: 900;
  color: var(--day-block-event-royal-color);
} 

.dayEventIndicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--day-block-event-color);
  position: absolute;
  bottom: 9px;
}
.dayEventIndicatorToday {
  background-color: var(--day-block-event-today-color);
}

.dayEventIndicatorImage {
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 6px;
}
.dayEventAuspiciousIndicator {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 8px;
  left: 12px;
}

.dayEventIndicatorJP {
  background-color: var(--day-block-event-jp-color);
}

.headerWrapper {
  padding: 4.5rem;
  /* margin-bottom: 1.25rem; */  

  background-size: cover;
  background-position: top;

  font-weight: bold;
}
.headerToolbar {
  margin: 0 !important;
  padding: 1.2rem 12px 0.4rem;
  background-color: var(--calendar-bg-color);
  color: rgba(0, 0, 0, 0.8);
  box-shadow: var(--calendar-header-shadow);
}
.calendarHeaderTitle {
  font-size: 1.5rem;
  color: var(--calendar-header-foreground-color);
  font-weight: bold;
  font-family: var(--font);
}

.stickerBackground {
  background-color: white;
}

.stickerModalStickerWrapper{
  max-width: 20rem;
  max-height: 20rem;
  margin: 0 auto 20rem;
}
.stickerModalSticker {
  margin: 0 auto;
}
.stickerCloseButton {
  background-color: var(--secondary-color);
}

.motivationalBlock {
  background-color: var(--accent-color);
  color: white;
  box-shadow: 0 5px 8px 3px rgba(186,177,231,0.55);
}

.modeLinkColor {
  color: var(--secondary-color);
}