.contentBox {
  position: relative;
  flex: 1;
  border-top-left-radius: 2.6rem;
  border-top-right-radius: 2.6rem;
  background-image: var(--content-background-image);
  padding: 2.8rem 1.4rem 5.2rem;
  box-shadow: 0 15px 70px 17px rgba(132,106,255,0.40);
}

.eventBlock {
  background-color: white;
  border-radius: 1.2rem;
  padding: 0.8rem;
  right: 10px;
  box-shadow: 0 0 21px -8px rgba(116,97,207,0.20);
  z-index: 4;
  margin-top: -5rem;
  margin-bottom: 1rem;
}

.eventItemWrapper {
  padding: 0;
}
.eventItemWrapper + .eventItemWrapper {
  margin-top: -2.8rem;
}
.eventThumbnail {
  border-radius: 1.4rem;
  width: 128px;
}
.eventItemNoThumbnail {
  padding: 1.8rem;
}
.eventCircle {
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
  border-radius: 8px;
}

.eventText {
  color: var(--event-description-foreground-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: var(--primary-color);
}

.tabbar > ul {
  border-bottom: 0;
}

.tabbarItem {
  flex: 1;
  width: 0;
}

.tabbarItemContentWrapper {
  border-bottom: 0;
  font-family: var(--font);
  font-size: 0.9rem;
  font-weight: 900;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabbarItemContentWrapper > span {
  color: var(--tab-bar-item-foreground-color);
  margin-right: 0 !important;
}

.tabbarItemContentWrapper > span > svg {
  fill: var(--tab-bar-item-foreground-color);
}

.activeTabbarItem > .tabbarItemContentWrapper > span {
  color: var(--tab-bar-active-item-foreground-color);;
}

.activeTabbarItem > .tabbarItemContentWrapper > span > svg {
  fill: var(--tab-bar-active-item-foreground-color);
}