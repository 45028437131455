body {
  --primary-color: #7461cf;
  --secondary-color: #332066;
  --accent-color: #FFA822;
  --primary-foreground-color: white;
  --accent-foreground-color: #523912;
  --secondary-foreground-color: #FAFBFF;

  /* Calendar settings */
  --app-bg-color: #F4F2FF;
  --calendar-bg-color: #F4F2FF;
  --calendar-header-foreground-color: var(--primary-color);
  --calendar-header-shadow: inset 0 -1px 1px 0 rgba(116,97,207,0.10);
  --dayBlock-header-foreground-color: var(--primary-color);
  --day-block-border-color: #F1EFFA;
  --day-block-today-color: var(--primary-color);
  --day-block-today-foreground-color: var(--primary-foreground-color);
  --day-block-today-shadow: 0px 3px 7px -3px #7461CF;
  --day-block-weekend-color: #ACA0E2;
  --day-block-event-color: var(--primary-color);
  --day-block-event-today-color: var(--secondary-foreground-color);
  --day-block-event-jp-color: #BC002D;
  --day-block-event-royal-color: #BC002D;
  --font: 'Nunito', 'Kanit', sans-serif !important;
  /* End of Calendar settings */

  /* Content settings */
  --content-background-image: linear-gradient(24deg, #8B72FF 0%, #936BEC 29%, #9F61CF 52%, #6D54B4 79%, #6151AD 100%);
  --tab-bar-item-foreground-color: rgba(255,255,255,0.5);
  --tab-bar-active-item-foreground-color: #FFD799;
  --event-description-foreground-color: var(--primary-color);
  /* End of Content settings */

  background-color: var(--app-bg-color);
}